import { useSelector } from "react-redux";
import {
  selectSelectedElement,
  selectSelectedTake3D,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import {
  clickIndicatorStyles,
  IClickIndicator,
} from "../../../models/interactions/ClickIndicator";
import usePropHelper from "../../../hooks/PropHelper";
import { useMemo } from "react";
import PropDropdown from "../grab/PropDropdown";
import PiInteractionStringDropdown from "../../../components/PiInteractionStringDropdown";
import { useDispatch } from "react-redux";

export default function ClickIndicatorPreviewBody() {
  const indicator = useSelector(selectSelectedElement) as IClickIndicator;
  const { getUsedPropByInstanceId } = usePropHelper();
  const dispatch = useDispatch();

  const onChangePropGuid = (propGuid: string) => {
    dispatch(
      updateSelectedInteraction({
        ...indicator,
        propGuid,
        tag: "",
      } as IClickIndicator)
    );
  };

  const onChangeTag = (tagName: string) => {
    const prop = getUsedPropByInstanceId(indicator.propGuid);

    const index = prop.metadata.clickableTags.findIndex(
      (t) => t.name === tagName
    );
    const tag = prop.metadata.clickableTags[index].tag;

    dispatch(
      updateSelectedInteraction({ ...indicator, tag } as IClickIndicator)
    );
  };

  const onChangeField = (field: keyof IClickIndicator, value: any) => {
    dispatch(updateSelectedInteraction({ ...indicator, [field]: value }));
  };

  const clickableTags = useMemo(() => {
    if (!indicator.propGuid) return undefined;
    return getUsedPropByInstanceId(indicator.propGuid).metadata.clickableTags;
  }, [getUsedPropByInstanceId, indicator.propGuid]);

  const propDropdown = (
    <PropDropdown
      label="Prop"
      onClick={(propGuid) => onChangePropGuid(propGuid)}
      selectedPropStateId={indicator.propGuid}
    />
  );

  const tagDropdown = (
    <PiInteractionStringDropdown
      label="Tag"
      onClick={(tag) => onChangeTag(tag)}
      items={clickableTags?.map((t) => t.name) ?? []}
      selectedItem={
        indicator.tag === ""
          ? "No tag selected"
          : clickableTags?.find((ct) => ct.tag === indicator.tag)?.name ??
            "No tag selected"
      }
    />
  );

  const stylesDropdown = (
    <PiInteractionStringDropdown
      label="Style"
      onClick={(style) => onChangeField("style", style)}
      items={clickIndicatorStyles}
      selectedItem={indicator.style}
    />
  );

  return (
    <div className="flex flex-col w-full h-full gap-[12px]">
      {propDropdown}
      {tagDropdown}
      {stylesDropdown}
    </div>
  );
}
