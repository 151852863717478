import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import SlideshowPreview from "../../features/previews/slideshow/SlideshowPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface ISlideshow extends Interaction {
    assetIds: string[]
}

export const slideshowInteraction = (): InteractionFactoryEntity => {

    const component = <SlideshowPreview />

    const create = (): ISlideshow => {
        return {
            id: GetNewGuid(),
            name: "Slideshow",
            type: InteractionType.Slideshow,
            assetIds: [""],
        }
    }

    const migrate = (from: number, to: number, interaction: Interaction) => {
        return interaction;
    }

    const copy = (interaction: Interaction): ISlideshow => {
        const converted = interaction as ISlideshow;
        return {
            id: GetNewGuid(),
            name: converted.name,
            type: InteractionType.Slideshow,
            assetIds: converted.assetIds,
            properties: converted.properties
        }
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as ISlideshow;
        const result: string[] = [];

        for (let i = 0; i < converted.assetIds.length; i++) {
            ValidationHelper.ValidateAssetId(converted.assetIds[i], result, `Image ${i + 1} does not have a valid image`)
        }

        return result;
    }

    const filter = (interaction: Interaction): string[] => {
        return [interaction.name];
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate,
        Filter: filter
    }
}