import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectActors, selectTakes } from "../../app/slices/TrainingSlice";
import { PiFilterDropDownListItem } from "../../components/PiFilterDropDown";
import { Actor, ProfilePictures } from "../../models/Actor";
import { IConversation } from "../../models/interactions/Conversation";
import { IEmotionBubble } from "../../models/interactions/EmotionBubble";
import {
  FilterType,
  getFilterType,
} from "../../models/interactions/FilterType";
import Interaction, {
  InteractionType,
} from "../../models/interactions/Interaction";
import { IMessageBubble } from "../../models/interactions/MessageBubble";
import Take from "../../models/Take";
import { getInteractionFromFactory } from "../interaction_factory/InteractionFactory";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import AdvancedSearchResultField from "./AdvancedSearchResultField";

interface AdvancedSearchListProps {
  inputQuery: string;
  filterItems: PiFilterDropDownListItem[];
  handleFilterItems: (items: PiFilterDropDownListItem[]) => void;
}

export default function AdvancedSearchList(props: AdvancedSearchListProps) {
  const { inputQuery, handleFilterItems, filterItems } = props;
  const [resultsView, setResultsView] = useState<JSX.Element[]>();
  const [selectedResultId, setSelectedResultId] = useState<string>("");
  const allTakes: Take[] = useSelector(selectTakes);
  const allActors = useSelector(selectActors);
  const { getValue } = useTrainingLocalization();

  // Initialize the array
  const searchResultsData: {
    type: FilterType;
    count: number;
    checked: boolean;
  }[] = [];

  useEffect(() => {
    setResultsView(
      RenderList(
        allTakes,
        getValue,
        inputQuery,
        filterItems,
        allActors,
        setSelectedResultId,
        selectedResultId
      )
    );
  }, [filterItems]);

  // Function to update the filter list
  const updateFilterData = (type: FilterType) => {
    const existingItem = searchResultsData.find((item) => item.type === type);
    if (existingItem) {
      existingItem.count += 1;
    } else {
      searchResultsData.push({ type, count: 1, checked: true });
    }
  };

  // Trigger the callback to update the parent component's state after rendering
  useEffect(() => {
    setResultsView(
      RenderList(
        allTakes,
        getValue,
        inputQuery,
        filterItems,
        allActors,
        setSelectedResultId,
        selectedResultId,
        updateFilterData
      )
    );
    handleFilterItems(searchResultsData);
  }, [inputQuery]);

  //return <div className="">{resultsView}</div>;

  return (
    <div>
      {RenderList(
        allTakes,
        getValue,
        inputQuery,
        filterItems,
        allActors,
        setSelectedResultId,
        selectedResultId,
        updateFilterData
      )}
    </div>
  );
}

function getView(
  interaction: Interaction,
  takeName: string,
  key: string,
  inputQuery: string,
  matchingText: string,
  isHidden: boolean,
  onSelect: (resultId: string) => void,
  isSelected: boolean,
  profilePicture?: string
) {
  return (
    <AdvancedSearchResultField
      key={key}
      resultId={key}
      matchingText={matchingText}
      query={inputQuery}
      objectId={interaction.id}
      takeName={takeName}
      interactionType={interaction.type}
      isHidden={isHidden}
      profilePicture={profilePicture}
      onSelect={onSelect}
      isSelected={isSelected}
    />
  );
}

function RenderList(
  allTakes3D: Take[],
  getValue: (key: string) => string,
  inputQuery: string,
  filterItems: PiFilterDropDownListItem[],
  actors: Actor[],
  setSelectedResultId: (resultId: string) => void,
  selectedResultId: string,
  updateFilterData?: (type: FilterType) => void
): JSX.Element[] {
  const lowerCaseQuery = inputQuery.toLocaleLowerCase();

  return allTakes3D.flatMap((take) => {
    const matches: JSX.Element[] = [];

    //Search take name
    if (take.name.toLocaleLowerCase().includes(lowerCaseQuery)) {
      const isHidden: boolean = !filterItems.some(
        (item) => item.type === FilterType.Take && item.checked
      );

      matches.push(
        <AdvancedSearchResultField
          key={take.id}
          matchingText={take.name}
          query={inputQuery}
          objectId={take.id}
          isHidden={isHidden}
          onSelect={setSelectedResultId}
          resultId={take.id}
          isSelected={take.id === selectedResultId}
        />
      );

      if (typeof updateFilterData !== "undefined") {
        updateFilterData(FilterType.Take);
      }
    }

    // Temporary array to collect matches
    const newMatches: JSX.Element[] = [];

    //Search on take interactions
    take.interactions.forEach((interaction) => {
      const interactionFactory = getInteractionFromFactory(interaction.type);

      if (
        interactionFactory &&
        typeof interactionFactory.Filter === "function"
      ) {
        let filteredStrings = interactionFactory.Filter(interaction, getValue);
        let addToResults = false;

        filteredStrings.forEach((matchingString, index) => {
          //Use localized version for questions, answers etc.
          const localizedString = getValue(matchingString) || matchingString;
          const searchTarget = localizedString.toLocaleLowerCase();

          //The non-localized version for name of the interaction
          if (searchTarget.includes(lowerCaseQuery)) {
            const filterType: FilterType = getFilterType(interaction.type);

            const isHidden: boolean = !filterItems.some(
              (item) => item.type === filterType && item.checked
            );
            addToResults = true;

            //Show profile pic for message bubbles
            if (interaction.type === InteractionType.MessageBubble) {
              const avatarId = (interaction as IMessageBubble).npcId;

              if (avatarId) {
                const profilePicture: string =
                  avatarId === "player"
                    ? avatarId
                    : ProfilePictures[
                        actors.find((a) => a.id === avatarId)?.profilePicture ??
                          0
                      ];

                newMatches.push(
                  getView(
                    interaction,
                    take.name,
                    interaction.id + index,
                    inputQuery,
                    localizedString,
                    isHidden,
                    setSelectedResultId,
                    interaction.id + index === selectedResultId,
                    profilePicture
                  )
                );
              } else {
                newMatches.push(
                  getView(
                    interaction,
                    take.name,
                    interaction.id + index,
                    inputQuery,
                    localizedString,
                    isHidden,
                    setSelectedResultId,
                    interaction.id + index === selectedResultId
                  )
                );
              }
            } else if (interaction.type === InteractionType.Conversation) {
              const messageInteraction:
                | IMessageBubble
                | IEmotionBubble
                | undefined = (interaction as IConversation).messages.find(
                (message) => {
                  if (message.type === InteractionType.MessageBubble) {
                    const localMessage = message as IMessageBubble;
                    if (localMessage.text === matchingString)
                      return localMessage;
                  }
                  return null;
                }
              );

              const avatarId = messageInteraction
                ? messageInteraction.npcId
                : "";

              if (avatarId) {
                const profilePicture: string =
                  avatarId === "player"
                    ? avatarId
                    : ProfilePictures[
                        actors.find((a) => a.id === avatarId)?.profilePicture ??
                          0
                      ];

                newMatches.push(
                  getView(
                    interaction,
                    take.name,
                    interaction.id + index,
                    inputQuery,
                    localizedString,
                    isHidden,
                    setSelectedResultId,
                    interaction.id + index === selectedResultId,
                    profilePicture
                  )
                );
              } else {
                newMatches.push(
                  getView(
                    interaction,
                    take.name,
                    interaction.id + index,
                    inputQuery,
                    localizedString,
                    isHidden,
                    setSelectedResultId,
                    interaction.id + index === selectedResultId
                  )
                );
              }
            } else {
              newMatches.push(
                getView(
                  interaction,
                  take.name,
                  interaction.id + index,
                  inputQuery,
                  localizedString,
                  isHidden,
                  setSelectedResultId,
                  interaction.id + index === selectedResultId
                )
              );
            }
          }
        });

        if (addToResults) {
          if (typeof updateFilterData !== "undefined") {
            updateFilterData(getFilterType(interaction.type));
          }
        }
      } else {
        console.error(
          `Filter function not found for interaction type: ${interaction.type}`
        );
      }
    });

    // Update matches after iteration
    matches.push(...newMatches);

    return matches;
  });
}
